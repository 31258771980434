<template>
	<div>
		<br />
		<div class="col-sm-12 text-right">
			<h1>Usuarios</h1>
		</div>
		<br />

		<DataGrid :configuracion="dataGridConfig" :data="usuarios" @seleccionadas="dataGridActions" :seleccionar="seleccionar">
			<div class="row">
				<div class="col-sm-2">
					<button class="btn warning-btn" @click="obtener_usuarios">Recargar</button>
				</div>
				<div class="col-sm-2">
					<button v-if="$auth.can('formalizacion','crear_usuarios')" class="btn principal-btn" @click="cargar_modal(1)">Nuevo</button>
				</div>
				<div class="col-sm-2">
					<button v-if="$auth.can('formalizacion','editar_usuarios')" class="btn secondary-btn" @click="cargar_modal(2)">Editar</button>
				</div>
				<div class="col-sm-2">
					<button v-if="$auth.can('formalizacion','eliminar_usuarios')" class="btn danger-btn" @click="pre_eliminar_usuario">Eliminar</button>
				</div>
			</div>
		</DataGrid>

		<Modal v-if="ver_usuario!=0" :options="{width: '40vw'}" @close="cancelar_modal" class="bpb-modal"> 
			<form @submit="guardar_form">
				<h2 class="title">Usuario</h2>
				<div class="body">
					<div class="row form-group">
						<label for="empresa" class="col-form-label col-sm-2">Empresa</label>
						<div v-if="ver_usuario==1" class="col-sm-10">
							<select v-model="usuario.empresa_id" name="empresa" id="empresa" class="form-control">
								<option v-for="empresa in empresas" :key="empresa.id" :value="empresa.id">{{ empresa.empresa }}</option>
							</select>
						</div>
						<div v-else class="col-sm-10">{{ ver_empresa }}</div>
					</div>
					<div class="row form-group">
						<label for="nombre" class="col-form-label col-sm-2">Nombre</label>
						<div class="col-sm-10"><input v-model="usuario.nombre" type="text" name="nombre" id="nombre" class="form-control"></div>
					</div>
					<div class="row form-group">
						<label for="email" class="col-form-label col-sm-2">Email</label>
						<div class="col-sm-10"><input v-model="usuario.email" type="email" name="email" id="email" class="form-control"></div>
					</div>
					<div class="row form-group">
						<label for="tipo" class="col-form-label col-sm-2">Tipo</label>
						<div class="col-sm-10">
							<select v-model="usuario.tipo" name="tipo" id="tipo" class="form-control">
								<option value="1">Evaluador</option>
								<option value="2">Notario</option>
							</select>
						</div>
					</div>
					<div class="row form-group">
						<label for="estatus" class="col-form-label col-sm-2">Estatus</label>
						<div class="col-sm-10">
							<select v-model="usuario.estatus" name="estatus" id="estatus" class="form-control">
								<option value="1">Activo</option>
								<option value="0">Inactivo</option>
							</select>
						</div>
					</div>
				</div>
				<div class="footer">
					<div class="row">
						<div v-if="ver_usuario!=1" class="col-sm-4">
							<button class="btn secondary-btn" type="button" @click="reenviar_correo">Reenviar correo</button>
						</div>
						<div :class="'col-sm-4'+(ver_usuario==1 ? ' offset-sm-4' : '')">
							<button class="btn principal-btn">Guardar</button>
						</div>
						<div class="col-sm-4">
							<button class="btn complementary-btn" type="button" @click="cancelar_modal">Cancelar</button>
						</div>
					</div>
				</div>
				<br />
			</form>
		</Modal>

		<Modal v-if="ver_eliminar_usuario" :options="{width: '40vw', type:'error'}" @close="cancelar_eliminar" class="bpb-modal">
			<h2 class="title">Eiminar usuario</h2>
			<div class="body">
				<div class="row">
					<div class="col-sm-12 text-center">¿Realmente deseas eliminar el usuario "{{ usuario.nombre }}"?</div>
				</div>
			</div>
			<div class="footer">
				<div class="row">
					<div class="col-sm-12 d-flex justify-content-end">
						<div class="col-sm-4">
							<button class="btn principal-btn" @click="eliminar_usuario">Eliminar</button>
						</div>
						<div class="col-sm-4">
							<button class="btn danger-btn" @click="cancelar_eliminar">Cancelar</button>
						</div>
					</div>
				</div>
			</div>
		</Modal>

	</div>
</template>

<script type="text/javascript">
	import Modal from '@/components/Modal'
	import DataGrid from '@/components/DataGridV3'

	import api from '@/apps/formalizacion/api/v1/formalizacion'

	import Empresas from './Empresa'

	export default {
		components: {
			Modal, DataGrid, Empresas
		}
		,data: function() {
			return {
				dataGridConfig: {
					name: 'usuarios'
					,cols: {
						id: 'ID'
						// ,organizacion: 'Empresa'
						,nombre: 'Usuario'
						,nombre: 'Nombre'
						,email: 'Email'
						,tipo: 'Tipo'
						,estatus: 'Estatus'
					}
					,mutators: {
						tipo: function(val) {
							return val == 1 ? 'Valuador' : 'Notario';
						}
						,estatus: function(val) {
							return val == 1 ? 'Activo' : 'Inactivo';
						}
					}
				}
				,seleccionar: false
				,seleccion: []
				,usuarios: []
				,usuario: {
					empresa_id: 0
					,usuario: null
					,nombre: null
					,email: null
					,tipo: 1
					,estatus: 1
				}

				//empresas
				,ver_empresas: false
				,empresas: []

				//usuario
				,ver_usuario: 0
				,ver_eliminar_usuario: false
			}
		}
		,mounted: function() {
			this.obtener_usuarios();
		}
		,methods: {
			obtener_usuarios: async function() {
				this.usuarios = (await api.obtener_usuarios(this.options)).data;
			}
			,dataGridActions: function(valor) {
				this.seleccion = valor;
				this.seleccionar = true;
			}

			//Modal usuarios
			,obtener_empresas: async function() {
				if (this.empresas.length == 0)
					this.empresas = (await api.obtener_empresas()).data;
			}
			,cargar_modal: function(tipo) {
				this.obtener_empresas();

				if (tipo == 1) {
					this.ver_usuario = 1;
					this.usuario.empresa_id = this.empresas[0].id;
				}else {
					if (this.seleccion.length > 0) {
						this.usuario = this.seleccion[0];
						this.ver_usuario = 2;
					}else
						this.$helper.showMessage('Error','Tienes que seleccionar el usuario a editar', 'error', 'alert');
				}
			}
			,cancelar_modal: function() {
				this.usuario = {
					empresa_id: 0
					,usuario: null
					,nombre: null
					,email: null
					,tipo: 1
					,estatus: 1
				};
				this.ver_usuario = 0;
				this.seleccionar = false;
			}
			,guardar_form: async function(e) {
				e.preventDefault();

				try {
					if (this.ver_usuario == 1) {
						delete this.usuario.usuario;
						await api.crear_usuario(this.usuario);
					}else{
						delete this.usuario.empresa_id;
						delete this.usuario.usuario;
						delete this.usuario.account_id;
						delete this.usuario.created_at;
						delete this.usuario.updated_at;

						await api.actualizar_usuario(this.usuario.id, this.usuario);
					}

					this.cancelar_modal();
					this.obtener_usuarios();
				}catch(e) {
					this.$log.info('error', e);
					this.$helper.showAxiosError(e, 'Error');
				}
			}
			,pre_eliminar_usuario: function() {
				if (this.seleccion.length > 0) {
					this.usuario = this.seleccion[0];
					this.ver_eliminar_usuario = true;
				}else
					this.$helper.showMessage('Error','Tienes que seleccionar el usuario a eliminar', 'error', 'alert');
			}
			,eliminar_usuario: async function() {
				try {
					await api.eliminar_usuario(this.usuario.id);

					this.cancelar_eliminar();
					this.obtener_usuarios();
				}catch(e) {
					this.$log.info('error', e);
					this.$helper.showAxiosError(e, 'Error');
				}
			}
			,cancelar_eliminar: function() {
				this.ver_eliminar_usuario = false;
				this.seleccionar = false;
				this.usuario = {
					empresa_id: 0
					,usuario: null
					,nombre: null
					,email: null
					,tipo: 1
					,estatus: 1
				};
			}
			,reenviar_correo: async function() {
				let res = (await api.reenviar_correo(this.usuario.id)).data;

				if (res.success)
					this.$helper.showMessage('Correo enviado', 'El correo se envio de forma satisfactoria');
				else
					this.$helper.showMessage('Error', 'El correo no se pudo enviar: '+res.message, 'error', 'alert');
			}
		}
		,computed: {
			ver_empresa: function() {
				let empresa;

				for(let i=0; i<this.empresas.length; i++) {
					if (this.empresas[i].id == this.usuario.empresa_id) {
						empresa = this.empresas[i].empresa;
						i = this.empresas.length;
					}
				}

				return empresa;
			}
		}
	}
</script>